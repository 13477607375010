import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-08836234"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "edit-slot-booking" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = { class: "section" };
const _hoisted_4 = { class: "title" };
const _hoisted_5 = { class: "checkboxes-wrapper" };
const _hoisted_6 = { class: "section" };
const _hoisted_7 = { class: "title" };
const _hoisted_8 = { class: "row" };
const _hoisted_9 = { class: "col-xl-7" };
const _hoisted_10 = { class: "section" };
const _hoisted_11 = { class: "section" };
const _hoisted_12 = { class: "title" };
const _hoisted_13 = { class: "row" };
const _hoisted_14 = { class: "col-xl-7" };
const _hoisted_15 = { class: "d-flex" };
const _hoisted_16 = { class: "generic-input-field" };
const _hoisted_17 = { class: "ms-2 me-2" };
const _hoisted_18 = { class: "generic-input-field" };
const _hoisted_19 = { class: "section" };
const _hoisted_20 = { class: "title" };
const _hoisted_21 = { class: "row" };
const _hoisted_22 = { class: "col-xl-7" };
const _hoisted_23 = { class: "small-title" };
const _hoisted_24 = { class: "d-flex" };
const _hoisted_25 = { class: "col-6" };
const _hoisted_26 = { class: "col-6" };
const _hoisted_27 = { class: "section" };
const _hoisted_28 = { class: "section" };
const _hoisted_29 = { class: "title" };
const _hoisted_30 = { class: "row" };
const _hoisted_31 = { class: "col-xl-7" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_RadioButton = _resolveComponent("RadioButton");
    const _component_FormField = _resolveComponent("FormField");
    const _component_InputLabel = _resolveComponent("InputLabel");
    const _component_AppCheckbox = _resolveComponent("AppCheckbox");
    const _component_InputText = _resolveComponent("InputText");
    const _component_FormError = _resolveComponent("FormError");
    const _component_InputUrl = _resolveComponent("InputUrl");
    const _component_AttachmentsInput = _resolveComponent("AttachmentsInput");
    const _component_InputNumber = _resolveComponent("InputNumber");
    const _component_Calendar = _resolveComponent("Calendar");
    const _component_AppForm = _resolveComponent("AppForm");
    const _directive_default_validation = _resolveDirective("default-validation");
    return (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
            key: 0,
            center: ""
        }))
        : (_openBlock(), _createBlock(_component_AppForm, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.model) = $event)),
            initial: _ctx.initial,
            errors: _ctx.errors
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_FormField, null, {
                        default: _withCtx(() => [
                            _createVNode(_component_RadioButton, {
                                name: "required",
                                dictionary: _ctx.dictionaries.slotBookingRequired,
                                dictionaryId: "SlotBookingRequired"
                            }, null, 8, ["dictionary"])
                        ]),
                        _: 1
                    }),
                    (_ctx.isBookingRequired)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("section", _hoisted_3, [
                                _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.translate("slot-booking-by")), 1),
                                _createElementVNode("div", _hoisted_5, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkBoxes, (checkbox) => {
                                        return (_openBlock(), _createBlock(_component_FormField, {
                                            class: "d-flex",
                                            key: checkbox.id
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_AppCheckbox, {
                                                    modelValue: checkbox.value,
                                                    "onUpdate:modelValue": [($event) => ((checkbox.value) = $event), ($event) => (_ctx.updateCheckbox($event, checkbox.id))]
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_InputLabel, { class: "checkbox-label" }, {
                                                            default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(_ctx.dictionaryTranslate(_ctx.slotBookingByDictionaryId, checkbox)), 1)
                                                            ]),
                                                            _: 2
                                                        }, 1024)
                                                    ]),
                                                    _: 2
                                                }, 1032, ["modelValue", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                        }, 1024));
                                    }), 128))
                                ])
                            ]),
                            _createElementVNode("section", _hoisted_6, [
                                _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.translate("slot-booking-procedure")), 1),
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("div", _hoisted_9, [
                                        _createVNode(_component_FormField, null, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_InputLabel, null, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.translate("components.edit-slot-booking.booking-by-phone-label")), 1)
                                                    ]),
                                                    _: 1
                                                }),
                                                _withDirectives(_createVNode(_component_InputText, {
                                                    name: "procedure.phoneNumber",
                                                    placeholder: _ctx.translate('form-fields.telephone.placeholder'),
                                                    required: ""
                                                }, null, 8, ["placeholder"]), [
                                                    [_directive_default_validation]
                                                ]),
                                                _createVNode(_component_FormError, { name: "procedure.phoneNumber" })
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_FormField, null, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_InputLabel, null, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.translate("components.edit-slot-booking.booking-by-mail-label")), 1)
                                                    ]),
                                                    _: 1
                                                }),
                                                _withDirectives(_createVNode(_component_InputText, {
                                                    name: "procedure.email",
                                                    placeholder: _ctx.translate('booking-by-mail'),
                                                    email: ""
                                                }, null, 8, ["placeholder"]), [
                                                    [_directive_default_validation]
                                                ]),
                                                _createVNode(_component_FormError, { name: "procedure.email" })
                                            ]),
                                            _: 1
                                        }),
                                        _createVNode(_component_FormField, null, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_InputLabel, null, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.translate("components.edit-slot-booking.booking-by-online-label")), 1)
                                                    ]),
                                                    _: 1
                                                }),
                                                _withDirectives(_createVNode(_component_InputUrl, {
                                                    name: "procedure.onlineBooking",
                                                    placeholder: _ctx.translate('components.edit-slot-booking.booking-by-online-placeholder'),
                                                    "url-with-optional-schema": ""
                                                }, null, 8, ["placeholder"]), [
                                                    [_directive_default_validation]
                                                ]),
                                                _createVNode(_component_FormError, { name: "procedure.onlineBooking" })
                                            ]),
                                            _: 1
                                        })
                                    ])
                                ])
                            ]),
                            _createElementVNode("section", _hoisted_10, [
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_AttachmentsInput, {
                                            class: "mt-0 mb-3",
                                            attachments: _ctx.model.instructions,
                                            title: _ctx.translate('slot-booking-instructions'),
                                            name: "instructions",
                                            "empty-state-msg": _ctx.translate('phrases.no-additional-info-added')
                                        }, null, 8, ["attachments", "title", "empty-state-msg"]),
                                        _createVNode(_component_FormError, {
                                            name: `instructions[${_ctx.$utils.findErrorIndex(_ctx.errors)}].values`
                                        }, null, 8, ["name"])
                                    ]),
                                    _: 1
                                })
                            ]),
                            _createElementVNode("section", _hoisted_11, [
                                _createElementVNode("h5", _hoisted_12, _toDisplayString(_ctx.translate("slot-booking-cutoff-time")), 1),
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_RadioButton, {
                                            name: "cutoffTime.type",
                                            dictionary: _ctx.cutOfTimeDictionary,
                                            dictionaryId: _ctx.cutOfTimeDictionaryId
                                        }, null, 8, ["dictionary", "dictionaryId"])
                                    ]),
                                    _: 1
                                }),
                                _createElementVNode("div", _hoisted_13, [
                                    _createElementVNode("div", _hoisted_14, [
                                        (_ctx.isBeforeUnloadingLoadingDay)
                                            ? (_openBlock(), _createBlock(_component_InputLabel, {
                                                key: 0,
                                                class: "small-title"
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate("slot-booking-days-time")), 1)
                                                ]),
                                                _: 1
                                            }))
                                            : _createCommentVNode("", true),
                                        _createElementVNode("div", _hoisted_15, [
                                            (_ctx.isBeforeUnloadingLoadingDay)
                                                ? (_openBlock(), _createBlock(_component_FormField, {
                                                    key: 0,
                                                    class: "d-flex"
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_16, [
                                                            _createVNode(_component_InputNumber, {
                                                                name: "cutoffTime.days",
                                                                mode: "integer",
                                                                min: 0,
                                                                maxIntegerDigits: 4,
                                                                placeholder: _ctx.translate('slot-booking-days')
                                                            }, null, 8, ["placeholder"]),
                                                            _createVNode(_component_FormError, { name: "cutoffTime.days" })
                                                        ]),
                                                        _createElementVNode("span", _hoisted_17, [
                                                            (_ctx.isBeforeUnloadingLoadingDay)
                                                                ? (_openBlock(), _createBlock(_component_InputLabel, {
                                                                    key: 0,
                                                                    class: "hours-label"
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.translate("slot-booking-until")), 1)
                                                                    ]),
                                                                    _: 1
                                                                }))
                                                                : _createCommentVNode("", true)
                                                        ])
                                                    ]),
                                                    _: 1
                                                }))
                                                : _createCommentVNode("", true),
                                            (!_ctx.isTypeOfTimeNull)
                                                ? (_openBlock(), _createBlock(_component_FormField, {
                                                    key: 1,
                                                    class: "d-flex"
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_18, [
                                                            _createVNode(_component_Calendar, {
                                                                modelValue: _ctx.cutoffTime,
                                                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.cutoffTime) = $event)),
                                                                name: "cutoffTime.time",
                                                                timeOnly: true,
                                                                hourFormat: "24",
                                                                stepMinute: 10,
                                                                placeholder: _ctx.translate('slot-booking-time')
                                                            }, null, 8, ["modelValue", "placeholder"]),
                                                            _createVNode(_component_FormError, { name: "cuttoffTime.time" })
                                                        ])
                                                    ]),
                                                    _: 1
                                                }))
                                                : _createCommentVNode("", true)
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("section", _hoisted_19, [
                                _createElementVNode("h5", _hoisted_20, _toDisplayString(_ctx.translate("components.edit-slot-booking.slot-re-booking-time")), 1),
                                _createElementVNode("div", _hoisted_21, [
                                    _createElementVNode("div", _hoisted_22, [
                                        _createElementVNode("h6", _hoisted_23, _toDisplayString(_ctx.translate("new-slot-booking-required")), 1),
                                        _createElementVNode("div", _hoisted_24, [
                                            _createVNode(_component_FormField, { class: "d-flex col-6" }, {
                                                default: _withCtx(() => [
                                                    _createElementVNode("div", _hoisted_25, [
                                                        _createVNode(_component_InputNumber, {
                                                            name: "rebookingTime.hours",
                                                            mode: "integer",
                                                            min: 0,
                                                            max: 240,
                                                            placeholder: _ctx.translate('slot-booking-hours')
                                                        }, null, 8, ["placeholder"]),
                                                        _createVNode(_component_FormError, { name: "rebookingTime.hours" })
                                                    ]),
                                                    _createVNode(_component_InputLabel, { class: "hours-label" }, {
                                                        default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.translate("slot-booking-hours")), 1)
                                                        ]),
                                                        _: 1
                                                    })
                                                ]),
                                                _: 1
                                            }),
                                            _createVNode(_component_FormField, { class: "d-flex col-6" }, {
                                                default: _withCtx(() => [
                                                    _createElementVNode("div", _hoisted_26, [
                                                        _createVNode(_component_InputNumber, {
                                                            name: "rebookingTime.minutes",
                                                            min: 0,
                                                            max: 59,
                                                            placeholder: _ctx.translate('slot-booking-minutes')
                                                        }, null, 8, ["placeholder"]),
                                                        _createVNode(_component_FormError, { name: "rebookingTime.minutes" })
                                                    ]),
                                                    _createVNode(_component_InputLabel, { class: "hours-label" }, {
                                                        default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.translate("slot-booking-minutes")), 1)
                                                        ]),
                                                        _: 1
                                                    })
                                                ]),
                                                _: 1
                                            })
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("section", _hoisted_27, [
                                _createVNode(_component_FormField, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_AttachmentsInput, {
                                            class: "mt-0 mb-3",
                                            attachments: _ctx.model.instructionsForMissedSlots,
                                            title: _ctx.translate('components.edit-slot-booking.slot-booking-missed-slot'),
                                            name: "instructionsForMissedSlots",
                                            "empty-state-msg": _ctx.translate('phrases.no-additional-info-added')
                                        }, null, 8, ["attachments", "title", "empty-state-msg"]),
                                        _createVNode(_component_FormError, {
                                            name: `instructionsForMissedSlots[${_ctx.$utils.findErrorIndex(_ctx.errors)}].values`
                                        }, null, 8, ["name"])
                                    ]),
                                    _: 1
                                })
                            ]),
                            _createElementVNode("section", _hoisted_28, [
                                _createElementVNode("h5", _hoisted_29, _toDisplayString(_ctx.translate("slot-booking-request-slot")), 1),
                                _createElementVNode("div", _hoisted_30, [
                                    _createElementVNode("div", _hoisted_31, [
                                        _createVNode(_component_FormField, null, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_InputLabel, null, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.translate("components.edit-slot-booking.booking-by-online-label")), 1)
                                                    ]),
                                                    _: 1
                                                }),
                                                _withDirectives(_createVNode(_component_InputUrl, {
                                                    name: "newSlot",
                                                    placeholder: _ctx.translate('components.edit-slot-booking.booking-by-online-placeholder'),
                                                    "url-with-optional-schema": ""
                                                }, null, 8, ["placeholder"]), [
                                                    [_directive_default_validation]
                                                ]),
                                                _createVNode(_component_FormError, { name: "newSlot" })
                                            ]),
                                            _: 1
                                        })
                                    ])
                                ])
                            ])
                        ]))
                        : _createCommentVNode("", true)
                ])
            ]),
            _: 1
        }, 8, ["modelValue", "initial", "errors"]));
}
