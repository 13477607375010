export const emptySlotBooking = (requirement) => ({
    required: requirement,
    cutoffTime: {
        type: null,
        time: null,
        days: null,
    },
    slotBookingBy: [],
    procedure: {
        phoneNumber: null,
        email: null,
        onlineBooking: null,
    },
    instructions: [],
    rebookingTime: {
        hours: null,
        minutes: null,
    },
    instructionsForMissedSlots: [],
    newSlot: null,
});
